import * as dayJs from "dayjs";
import * as Yup from "yup";
import _ from "lodash";

import {
  TableCell,
  TableBody,
  TableHead,
  TableSortLabel,
  TableRow,
  Checkbox,
  withStyles,
  TextField,
  Select,
  ListItemIcon,
  Box,
  Typography,
  Button,
  DialogActions,
  Grid,
  Table,
} from "@material-ui/core";
import { Delete, ExpandMore } from "@material-ui/icons";
import DisplayPaymentMode from "app/core/utils/display-payment-mode.service";

import {
  FORMAT_DATE_MOMENT,
  FORMAT_DATE_MOMENT_FR,
  FORMAT_YEAR_MOMENT,
  FORMAT_DATE_MOMENT_FR_2,
  SEARCH_TYPE,
  PERIODICITY,
  DISPLAY_PAYMENT_MODE,
} from "app/core/utils/default-constant";
import { toast } from "react-toastify";
import { BsFillTelephoneFill, BsTelephone } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { BiWorld } from "react-icons/bi";

const isBetween = require("dayjs/plugin/isBetween");
dayJs.extend(isBetween);

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F4F4F9",
    color: "#454E99",
    font: "normal normal medium 45px/54px Mada",
    fontSize: "15px",
    height: "45px",
  },
  body: {
    font: "normal normal medium 40px/52px Mada",
    fontSize: "13px",
    height: "45px",
  },
}))(TableCell);

const StyledTableCellPreview = withStyles((theme) => ({
  root: {
    padding: 10,
  },
  head: {
    backgroundColor: "#f4f4f4",
    color: "##bbbbbb",
    fontSize: "11px",
  },
  body: {
    fontSize: "11px",
    border: "1px solid #dddddd",
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: " rgba(157, 157, 157, 0.04)",
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCellPreviewAdvance = withStyles((theme) => ({
  root: {
    padding: 10,
  },
  head: {
    backgroundColor: "#f4f4f4",
    color: "##bbbbbb",
    fontSize: "11px",
  },
  body: {
    fontSize: "11px",
    border: "1px solid #dddddd",
  },
}))(TableCell);
const StyledTableRowAdvance = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: " rgba(157, 157, 157, 0.04)",
    },
  },
}))(TableRow);

export const HeadCellsApportCca = [
  {
    id: "CODE",
    label: "N°Apport",
  },

  {
    id: " NOM_APPORTEUR",
    label: " Nom Apporteur",
  },
  {
    id: "MONTANT_TOTAL",
    label: "Montant Total ",
  },
  {
    id: "MONTANT_ECHEANCE",
    label: "Montant à l’Échéance",
  },
  {
    id: "DATE_ECHEANCE",
    label: "Date Échéance",
  },
  {
    id: "MONTANT_RESTANT",
    label: "Montant Restant",
  },
  {
    id: "REMBOURSABLE",
    label: "Remboursable",
  },
  {
    id: "REMUNERABLE",
    label: "Rémunérable",
  },
  {
    id: "NB_ECHEANCE",
    label: "Nombre Échéance",
  },
  {
    id: "STATUS",
    label: "Statut",
  },
  {
    id: "ACTIONS",
    label: "Actions",
  },
];

export const HeadCellsDevis = [
  {
    id: "N°_DEVIS",
    label: "N° devis",
  },
  {
    id: "DATE_DEVIS",
    label: "Date du devis",
  },
  {
    id: "CLIENT",
    label: "Client",
  },
  {
    id: "MONTANT_ACCOMPT",
    label: "Montant de l'acompte",
  },
  {
    id: "TOTAL_HT",
    label: "Total HT",
  },
  {
    id: "TOTAL_TTC",
    label: "Total TTC",
  },
  {
    id: "STATUT",
    label: "Statut",
  },
  {
    id: "ACTIONS",
    label: "Actions",
  },
];

export const HeaderCellsCashIn = [
  {
    id: "N_TRANCHE",
    label: "N°tranche",
  },
  {
    id: "DATE",
    label: "Date",
  },
  {
    id: "MODE DE PAIEMENT",
    label: "Mode de paiement",
  },
  {
    id: "MONTANT_PAYE",
    label: "Montant payé",
  },
  {
    id: "STATUT",
    label: "Statut",
  },
  {
    id: "ACTIONS",
    label: "Actions",
  },
];

export const HeaderCellsCashOuts = [
  {
    id: "Numero",
    label: "NºÉchéance",
  },
  {
    id: "DUE_DATE",
    label: "Date d'échéance",
  },
  {
    id: "MONTANT_PAYER",
    label: "Montant à payer",
  },
  {
    id: "MONTANT_RESTANT",
    label: "Montant restant",
  },
  {
    id: "STATUT",
    label: "Statut",
  },
  {
    id: "ACTIONS",
    label: "Actions",
  },
];

export const HeadCellsCommands = [
  {
    id: "N°_COMMANDE",
    label: "N° commande",
  },
  {
    id: "DATE_COMMANDE",
    label: "Date de la commande",
  },
  {
    id: "CLIENT",
    label: "Client",
  },
  {
    id: "MONTANT_ACOMPTE",
    label: "Montant de l'acompte",
  },
  {
    id: "TOTAL_TTC",
    label: "Total TTC",
  },
  {
    id: "STATUT",
    label: "Statut",
  },
  {
    id: "ACTIONS",
    label: "Actions",
  },
];

export const HeadCellsApportCapital = [
  {
    id: "N°_APPORT",
    label: "N°Apport",
  },
  {
    id: "TYPE_APPORT",
    label: "Type",
  },
  {
    id: "NOM_APPORTEUR",
    label: "Apporteur",
  },
  {
    id: "MONTANT_TOTAL",
    label: "Montant",
  },
  {
    id: "DATE_ECHEANCE",
    label: "Date d’échce",
  },
  {
    id: "MONTANT_ECHEANCE",
    label: "Montant à l’échce",
  },
  {
    id: "MONTANT_RESTANT",
    label: "Montant restant",
  },
  {
    id: "STATUT",
    label: "Statut",
  },
  {
    id: "ACTIONS",
    label: "Actions",
  },
];

export const HeadCellsLivraisons = [
  {
    id: "N°_LIVRAISON",
    label: "N° Bon de Livraison",
  },
  {
    id: "DATE_LIVRAISON",
    label: "Date du bon de Livraison",
  },
  {
    id: "CLIENT",
    label: "Client",
  },
  {
    id: "MONTANT_ACOMPTE",
    label: "Montant de l'acompte",
  },
  {
    id: "TOTAL_HT",
    label: "Total HT",
  },
  {
    id: "TOTAL_TTC",
    label: "Total TTC",
  },
  {
    id: "STATUT",
    label: "Statut",
  },
  {
    id: "ACTIONS",
    label: "Actions",
  },
];
export const HeadCellsFactures = [
  {
    id: "N°_FACTURE",
    label: "N° facture",
  },
  {
    id: "DATE_FACTURE",
    label: "Date facture",
  },
  {
    id: "CLIENT",
    label: "Client",
  },
  {
    id: "MONTANT_ACCOMPTE",
    label: "Montant de l'acompte",
  },
  {
    id: "MONTANT_VERSE",
    label: "Montant versé",
  },
  {
    id: "MONTANT_DU",
    label: "Montant dû",
  },
  {
    id: "TOTAL_HT",
    label: "Total HT",
  },
  {
    id: "TOTAL_TTC",
    label: "Total TTC",
  },
  {
    id: "STATUT",
    label: "Statut",
  },
  {
    id: "ACTIONS",
    label: "Actions",
  },
];
export const HeadCellsAvoirs = [
  {
    id: "N°_AVOIR",
    label: "Avoir N°",
  },
  {
    id: "N°_FACTURE",
    label: "Facture N°",
  },
  {
    id: "CLIENT",
    label: "Client",
  },
  {
    id: "REMBOURSEMENT",
    label: "Mode de remboursement",
  },
  {
    id: "SOLDE_PAID",
    label: "Solde à payer",
  },
  {
    id: "DATE_AVOIR",
    label: "Date de l'avoir",
  },
  {
    id: "MONTANT",
    label: "Montant de l'avoir",
  },
  {
    id: "STATUT",
    label: "Statut",
  },
  {
    id: "ACTIONS",
    label: "Actions",
  },
];
export const HeadCellsAcomptes = [
  {
    id: "N°_ACOMPTE",
    label: "N° acompte",
  },
  {
    id: "CLIENT",
    label: "Client",
  },
  {
    id: "DATE",
    label: "Date d'échéance",
  },
  {
    id: "AMOUNT",
    label: "Montant de l'acompte",
  },
  {
    id: "TTC",
    label: "Montant TTC",
  },
  {
    id: "SOURCE",
    label: "Source de création",
  },
  {
    id: "STATUT",
    label: "Statut",
  },
  {
    id: "ACTIONS",
    label: "Actions",
  },
];
export const HeadCellsRefBudgetaires = [
  {
    id: "COMPTE_BUDGETAIRE",
    label: "Numéro de compte budgétaire",
  },
  {
    id: "COMPTE_COMPTABLE",
    label: "Numéro de compte comptable",
  },
  {
    id: "LIBELLE_BUDGETAIRE",
    label: "Libellé compte budgétaire",
  },
  {
    id: "LIBELLE_TYPE_BUDGETAIRE",
    label: "Type budgétaire",
  },
  {
    id: "ACTIONS",
    label: "Actions",
  },
];
export const HeadCellsDemandeDevis = [
  {
    id: "N°",
    label: "",
  },
  {
    id: "N°_DEMANDE",
    label: "N° Demande devis",
  },
  {
    id: "DATE_DEMANDE_DEVIS",
    label: "Date demande de devis",
  },
  {
    id: "DATE_VALIDITE",
    label: "Date de validité",
  },
  {
    id: "FOURNISSEUR",
    label: "Fournisseur",
  },
  {
    id: "TOTAL_HT",
    label: "Total HT",
  },
  {
    id: "TOTAL_TTC",
    label: "Total TTC",
  },
  {
    id: "STATUT",
    label: "Statut",
  },
  {
    id: "ACTIONS",
    label: "Actions",
  },
];
export const HeadCellsCommandes = [
  {
    id: "N°_COMMANDE",
    label: "N° commande",
  },
  {
    id: "DATE_COMMANDE",
    label: "Date commande",
  },
  {
    id: "FOURNISSEUR",
    label: "Fournisseur",
  },
  {
    id: "TOTAL_HT",
    label: "Total HT",
  },
  {
    id: "TOTAL_TTC",
    label: "Total TTC",
  },
  {
    id: "STATUT",
    label: "Statut",
  },
  {
    id: "ACTIONS",
    label: "Actions",
  },
];
export const HeadCellsBonsReceptions = [
  {
    id: "N°_RECEPTION",
    label: "N° Bon de Recéption",
  },
  {
    id: "N°_COMMANDE",
    label: "N° Bon de commande",
  },
  {
    id: "DATE_RECEPTION",
    label: "Date de recéption",
  },
  {
    id: "FOURNISSEUR",
    label: "Fournisseur",
  },
  {
    id: "QUANTITE",
    label: "Qté commandée/ Qté receptionnée",
  },
  {
    id: "TOTAL_HT",
    label: "Total HT",
  },
  {
    id: "TOTAL_TTC",
    label: "Total TTC",
  },
  {
    id: "STATUT",
    label: "Statut",
  },
  {
    id: "ACTIONS",
    label: "Actions",
  },
];
export const HeadCellsFactureAchats = [
  {
    id: "N°_PIECE",
    label: "N° pièce",
  },
  {
    id: "FOURNISSEUR",
    label: "Fournisseur",
  },
  {
    id: "DATE_DEPENSE",
    label: "Date depense",
  },
  {
    id: "TOTAL_REGLE",
    label: "total réglé",
  },
  {
    id: "RESTANT_DU",
    label: "restant dû",
  },
  {
    id: "TOTAL_HT",
    label: "Total HT",
  },
  {
    id: "TOTAL_TTC",
    label: "Total TTC",
  },
  {
    id: "STATUT",
    label: "Statut",
  },
  {
    id: "ACTIONS",
    label: "Actions",
  },
];
export const HeadCellsNoteDeFrais = [
  {
    id: "N°_NOTE",
    label: "N° note",
  },
  {
    id: "NOM",
    label: "Nom",
  },
  {
    id: "DATE_NOTE",
    label: "Date",
  },

  {
    id: "MONTANT",
    label: "Montant",
  },
  {
    id: "STATUT",
    label: "Statut",
  },
  {
    id: "ACTIONS",
    label: "Actions",
  },
];

export const HeadCellsSubvention = [
  {
    id: "N°Subventions",
    label: "N°Subventions",
  },
  {
    id: "Type_Subventions",
    label: "Type",
  },
  {
    id: "Nom_créditeur",
    label: "Créditeur",
  },
  {
    id: "Montant_Total ",
    label: "Montant Total ",
  },

  {
    id: "Montant_échéance",
    label: "Montant à l’échce",
  },
  {
    id: "Date_échéance",
    label: "Date d’échce",
  },
  {
    id: "Montant_restant",
    label: "Montant restant",
  },
  {
    id: "STATUT",
    label: "Statut",
  },
  {
    id: "ACTIONS",
    label: "Actions",
  },
];

export const HeadCellsCustomerFolders = [
  {
    id: "CODE",
    label: "Code dossier",
  },
  {
    id: "RAISON_SOCIALE",
    label: "Raison sociale",
  },
  {
    id: "NUMERO_RC",
    label: "Numero RC",
  },
  {
    id: "SYSTEME_COMPTABLE",
    label: "Systeme comptable",
  },
  {
    id: "ACTIONS",
    label: "Actions",
  },
];

export const HeadCellsEmprunt = [
  {
    id: "N_Emprunt",
    label: "N°Emprunt",
  },

  {
    id: "N°_Crédit ",
    label: "N°Crédit",
  },
  {
    id: "Type_Emprunt",
    label: "Type emprunt",
  },
  {
    id: "Nom_créditeur",
    label: "Créditeur",
  },

  {
    id: "Montant_total",
    label: "Montant",
  },
  {
    id: "Date_echeance",
    label: "Date proch. échéance",
  },
  {
    id: "Montant_échéance  ",
    label: "Montant à l’échéance",
  },
  {
    id: "type_echeance",
    label: "Type d'echéance",
  },
  {
    id: "Montant_restant_après_échéance",
    label: "Montant restant",
  },
  {
    id: "statut",
    label: "Statut",
  },
  {
    id: "Actions",
    label: "Actions",
  },
];

export const HeadCellsCahsInApportCapital = [
  {
    id: "REFERENCE",
    label: "N°de l'apporteur",
  },

  {
    id: "CLIENT",
    label: "Nom de l'apporteur",
  },
  {
    id: "INVOICE_DATE",
    label: "Date d'échéance",
  },
  {
    id: "AMOUNT",
    label: "Montant",
  },
  {
    id: "statut",
    label: "Statut",
  },
  ,
  {
    id: "Actions",
    label: "Actions",
  },
];

export const HeaderCellsPaiment = [
  {
    id: "N_TRANCHE",
    label: "N tranche",
  },
  {
    id: "DATE",
    label: "Date",
  },
  {
    id: "MODE DE PAIEMENT",
    label: "Mode de paiement",
  },
  {
    id: "MONTANT_PAYE",
    label: "Montant payé",
  },
  {
    id: "STATUS",
    label: "Statut",
  },
  ,
  {
    id: "ACTION",
    label: "Action",
  },
];

export const HeaderCellsHistory = [
  {
    id: "DUE_DATE",
    label: "Date d'échéance",
  },
  {
    id: "MONTANT_PAYER",
    label: "Montant à payer",
  },
  {
    id: "MONTANT_RESTANT",
    label: "Montant restant",
  },

  {
    id: "STATUS",
    label: "Statut",
  },
  ,
  {
    id: "ACTION",
    label: "Action",
  },
];

export const HeadCellsCahsInApportCca = [
  {
    id: "REFERENCE",
    label: "N° de l'apport",
  },

  {
    id: "PARTNAIRE_NAME",
    label: "Nom de l'associer",
  },

  {
    id: "APPORT_AMOUNT",
    label: "Montant",
  },

  {
    id: "INVOICE_DATE",
    label: "Date d'échéance",
  },
  {
    id: "statut",
    label: "Statut",
  },
  ,
  {
    id: "Actions",
    label: "Actions",
  },
];
export const HeadCellsCahsInSubvention = [
  {
    id: "SUBVENTION",
    label: "N°Subvention",
  },

  {
    id: "LIMIT_DATE",
    label: "Date de l'échéance",
  },
  {
    id: "AMOUT",
    label: "Montant",
  },

  {
    id: "SUBVENTION_ORIGIN",
    label: "Origine de subvention",
  },
  {
    id: "statut",
    label: "Statut",
  },
  ,
  {
    id: "Actions",
    label: "Actions",
  },
];

export const HeadCellsEncaissementPret = [
  {
    id: "N_ENCAISSEMENT",
    label: "N°Encaissement",
  },
  {
    id: "PAYMENT_DATE",
    label: "Date de paiement",
  },
  {
    id: "LIMIT_DATE",
    label: "Date d'échéance",
  },
  {
    id: "Montant_total",
    label: "Montant total",
  },
  {
    id: "AMOUNT_PAID  ",
    label: "Montant payé",
  },
  {
    id: "AMOUNT_DU",
    label: "Montant restant",
  },

  {
    id: "statut",
    label: "Statut",
  },
  {
    id: "Actions",
    label: "Actions",
  },
];

export const HeaderCellsPayment = [
  {
    id: "N_TRANCHE",
    label: "N tranche",
  },
  {
    id: "DATE",
    label: "Date",
  },
  {
    id: "MODE DE PAIEMENT",
    label: "Mode de paiement",
  },
  {
    id: "MONTANT_PAYE",
    label: "Montant payé",
  },
];

export const HeadCellsVente = [
  {
    id: "REFERENCE",
    label: "Facture N°",
  },

  {
    id: "INVOICE_DATE",
    label: "Date facture",
  },

  {
    id: "TOTAL_HT",
    label: "Total ht",
  },
  {
    id: "TOTAL_TTC",
    label: "Total ttc",
  },

  {
    id: "TOTAL_REGLE",
    label: "Total réglé",
  },
  {
    id: "TOTAL_DU  ",
    label: "Total restant dû",
  },

  {
    id: "CLIENT",
    label: "Client",
  },

  {
    id: "statut",
    label: "Statut",
  },
  ,
  {
    id: "Actions",
    label: "Actions",
  },
];

export const renderDateFormat = (date, format) => {
  if (!format) {
    return dayJs(date).format(FORMAT_DATE_MOMENT_FR_2);
  }
  return dayJs(date).format(format);
};

export const getModuleDateFormat = (date, format) => {
  if (!format) {
    return dayJs(date).add(1, "day").format(FORMAT_DATE_MOMENT_FR_2);
  }
  return dayJs(date).add(1, "day").format(format);
};
export const renderYearFormat = (date, format) => {
  if (!format) {
    return dayJs(date).format(FORMAT_YEAR_MOMENT);
  }
  return dayJs(date).format(format);
};
export const renderDayFormat = (date, format) => dayJs(date).format(format);

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
export function stableSort(array, comparator) {
  const stabilizedThis = array && array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
export const handleRequestSort = (
  event,
  property,
  setOrder,
  setOrderBy,
  orderBy,
  order
) => {
  const isAsc = orderBy === property && order === "asc";
  setOrder(isAsc ? "desc" : "asc");
  setOrderBy(property);
};
export const setLimitPage = (limit_page, current_page, monthsTable) => {
  const finalMonthSelectedTable =
    monthsTable.length > 0 ? monthsTable.map((res) => res.value) : [];

  return {
    limit: limit_page ? limit_page : 10,
    page: current_page ? current_page : 1,
    months: finalMonthSelectedTable.join("_"),
  };
};
export const handleClick = (name, selected, setSelected) => {
  const selectedIndex = selected.indexOf(name);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, name);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
  }
  setSelected(newSelected);
};
export const handleSelectAllClick = (event, renderComponent, setSelected) => {
  if (event.target.checked) {
    const newSelecteds = renderComponent.map((n) => n);
    setSelected(newSelecteds);
    return;
  }
  setSelected([]);
};
export const CustomazationTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    setOrder,
    setOrderBy,
    setSelected,
    renderComponent,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property, setOrder, setOrderBy, orderBy, order);
  };

  return (
    <TableHead>
      <StyledTableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={(event) =>
              onSelectAllClick(event, renderComponent, setSelected)
            }
          />
        </StyledTableCell>
        {headCells?.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            style={{ fontSize: 15 }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
};

export const handleChangeTotalValue = (
  values,
  setTotalTTC,
  setTotalHT,
  setTotalTVA,
  setTotalREMISE,
  quantityFieldToUse
) => {
  let initialValues = {
    tva: 0,
    ttc: 0,
    ht: 0,
    globalDiscount: 0,
  };

  if (values && values.product && values.product.length > 0) {
    for (let i = 0; i < values.product.length; i++) {
      const produit = values.product[i];

      produit.tvaRate =
        (+produit.tvaRate && typeof +produit.tvaRate !== undefined) ||
        (+produit.tvaRate && typeof +produit.tvaRate === "string")
          ? +produit.tvaRate
          : 0;

      if (produit && produit.quantityNumberAvailable) {
        produit.quantityNumberAvailable =
          (+produit.quantityNumberAvailable &&
            typeof +produit.quantityNumberAvailable !== undefined) ||
          (+produit.quantityNumberAvailable &&
            typeof +produit.quantityNumberAvailable === "string")
            ? +produit.quantityNumberAvailable
            : 0;
      }

      if (
        produit &&
        produit.unitPriceValue &&
        (produit.unitPriceValue !== null || produit.unitPriceValue !== "")
      ) {
        const quantity =
          produit[quantityFieldToUse ? quantityFieldToUse : "quantityNumber"];
        produit.discountValue
          ? null
          : ((produit.discountValue = 0),
            (produit.discountType = "POURCENTAGE"));

        let unitPriceMinesQuantity = +produit.unitPriceValue * +quantity;
        let discountValue = 0;
        let discountRate = 0;

        produit.discountType == "MONTANT"
          ? ((discountValue = produit.discountValue),
            (discountRate = (discountValue * 100) / unitPriceMinesQuantity))
          : ((discountValue =
              (unitPriceMinesQuantity * produit.discountValue) / 100),
            (discountRate = produit.discountValue));

        unitPriceMinesQuantity -= discountValue;

        let totalHTOfProduct =
          produit.unitPriceType == "HT"
            ? unitPriceMinesQuantity
            : unitPriceMinesQuantity * (100 / (100 + +produit.tvaRate));

        let totalHTOfProductAfterDiscount = totalHTOfProduct;
        let tvaValue = (totalHTOfProductAfterDiscount * +produit.tvaRate) / 100;

        initialValues.tva += tvaValue;
        initialValues.globalDiscount += discountValue;
        initialValues.ht += totalHTOfProductAfterDiscount;
        initialValues.ttc += totalHTOfProductAfterDiscount + tvaValue;
      }
    }

    setTotalTTC(initialValues.ttc);
    setTotalHT(initialValues.ht);
    setTotalTVA(initialValues.tva);
    setTotalREMISE(initialValues.globalDiscount);
  }
};

export const CustomerTableCell = ({
  referentiel,
  name,
  onChange,
  budgetsTypes,
}) => {
  return (
    <>
      {name === "budgetaryAccountNumber" ? (
        <>
          <StyledTableCell style={{ fontSize: 15 }}>
            {referentiel.isEdit ? (
              <TextField
                size="small"
                type="text"
                value={referentiel[name]}
                name={name}
                onChange={(e) => onChange(e, referentiel)}
                fullWidth
                variant="standard"
              />
            ) : (
              referentiel[name]
            )}
          </StyledTableCell>
        </>
      ) : name === "budgetType" ? (
        <>
          <StyledTableCell style={{ fontSize: 14 }}>
            {referentiel.isEdit ? (
              <Select
                native
                fullWidth
                name="budgetType"
                onChange={(e) => onChange(e, referentiel)}
                color="secondary"
                variant="standard"
                style={{ fontSize: 14 }}
                size="small"
                defaultValue={
                  referentiel?.budgetType
                    ? referentiel.budgetType["id"]
                    : referentiel[name] || ""
                }
                IconComponent={() => (
                  <ExpandMore className="input-select-dropDownIcon mr-5 label-color" />
                )}
              >
                <option value="" disabled>
                  Type budgétaire
                </option>
                {budgetsTypes &&
                  budgetsTypes.map((budget) => (
                    <option value={budget.id} key={budget.id}>
                      {budget.typeName}
                    </option>
                  ))}
              </Select>
            ) : referentiel?.budgetType ? (
              referentiel.budgetType["typeName"]
            ) : (
              referentiel[name] || "N/A"
            )}
          </StyledTableCell>
        </>
      ) : (
        <>
          <StyledTableCell style={{ fontSize: 15 }}>
            {referentiel.isEdit ? (
              <TextField
                size="small"
                value={referentiel[name]}
                name={name}
                onChange={(e) => onChange(e, referentiel)}
                fullWidth
                variant="standard"
                color="secondary"
              />
            ) : (
              referentiel[name]
            )}
          </StyledTableCell>
        </>
      )}
    </>
  );
};

export const MyLocalString = (props) => {
  const num = props;
  return isNaN(num) ? 0 : num && num?.toLocaleString("fr-FR");
};

export const GetRowDataSelected = (selected, datas) => {
  if (selected[0]) {
    const rowData = datas.filter((data) => data?.item?.id === selected[0]);
    return rowData[0]?.type?.toUpperCase();
  }
};
export const getTotalAmount = (content) => {
  if (content) {
    return content.reduce(function (acc, curr) {
      return acc + (curr?.amount || curr);
    }, 0);
  }
  return 0;
};

export const getModuleName = (params) =>
  params && params === "DELIVERYNOTE" ? "DELIVERY_NOTE" : params;
export const generatRangeOfYearSelected = (yearSelected) => {
  let concatIncrementAndDecrementYear;
  let yearIncrementSelected = [];
  let yearDecrementSelected = [];
  let years;

  const generateArrayOfTenElement = [...Array(11).keys()];

  for (const index in generateArrayOfTenElement) {
    yearDecrementSelected.push(yearSelected - generateArrayOfTenElement[index]);
    yearIncrementSelected.push(yearSelected + generateArrayOfTenElement[index]);

    yearDecrementSelected.reverse().sort();
    concatIncrementAndDecrementYear = yearDecrementSelected.concat(
      yearIncrementSelected
    );
    years = [...new Set(concatIncrementAndDecrementYear)];
  }
  return years;
};
export const saveModuleByPreviewId = (content, params, livraisonPreviewId) => {
  const moduleName = [
    { key: "COMMAND", value: "command" },
    { key: "DELIVERY_NOTE", value: "delivery_note" },
    { key: "DELIVERYNOTE", value: "deliveryNote" },
    { key: "QUOTE", value: "quote" },
  ];

  for (let module of moduleName) {
    if (params && params === module.key) {
      return (content[module.value] = livraisonPreviewId);
    }
  }
};
export const depositValueConvert = (depositValues) => {
  if (Array.isArray(depositValues) && depositValues.length > 0) {
    return depositValues?.concat(depositValues);
  } else if (depositValues !== null && depositValues > 0) {
    return [depositValues];
  } else {
    return null;
  }
};
export const renderAdvances = (values) => {
  const renderPaymentMode = new DisplayPaymentMode();

  return (
    values &&
    values.advances &&
    values.advances.length > 0 &&
    values.advances.map((advance, index) => (
      <StyledTableRowAdvance key={index}>
        <StyledTableCellPreviewAdvance>
          {renderPaymentMode.getPayment(values?.paymentMode?.paymentMethod) ||
            "N/A"}
        </StyledTableCellPreviewAdvance>
        <StyledTableCellPreviewAdvance>
          {advance && advance?.paymentDate !== null
            ? renderDateFormat(advance.paymentDate)
            : "N/A"}
        </StyledTableCellPreviewAdvance>
        <StyledTableCellPreviewAdvance>
          {MyLocalString(advance.amount) || 0}
        </StyledTableCellPreviewAdvance>
      </StyledTableRowAdvance>
    ))
  );
};
export const maxLimitDate = (startDate, endDate, currentDate) => {
  const start = renderDateFormat(startDate, FORMAT_DATE_MOMENT);
  const end = renderDateFormat(endDate, FORMAT_DATE_MOMENT);
  const now = renderDateFormat(currentDate, FORMAT_DATE_MOMENT);

  if (dayJs(now).isBetween(start, end)) {
    return renderDateFormat(end, FORMAT_DATE_MOMENT) && now;
  } else {
    return renderDateFormat(end, FORMAT_DATE_MOMENT);
  }
};

export const renderDepositValues = (values, totalTTC) => {
  let currentDepositValues;

  const resultOfVeryfications =
    values && values.depositValues && values.depositValues.length > 0
      ? values.depositValues.filter((res) => {
          return res.acompteValue !== null && res.acompteValue !== "";
        })
      : [];

  if (resultOfVeryfications && resultOfVeryfications.length > 0) {
    return resultOfVeryfications.map((res) => {
      if (res && res.typeName === "POURCENTAGE" && totalTTC) {
        currentDepositValues = (res.acompteValue * totalTTC) / 100;
        return currentDepositValues;
      } else {
        currentDepositValues = res.acompteValue;
        return currentDepositValues;
      }
    });
  } else {
    currentDepositValues = null;
    return currentDepositValues;
  }
};

export const renderPaymentMode = (values) => {
  if (values && values.paymentMethod === "CREDIT_CARD") {
    return {
      paymentMethod: values.paymentMethod,
      cvc: values.cvc,
      expirationDate: values.expirationDate,
      cardNumber: values.cardNumber,
    };
  }

  if (values && values.paymentMethod === "MOBILE_PAYMENT") {
    return {
      paymentMethod: values.paymentMethod,
      mobileNumber: values.mobileNumber,
    };
  }

  if (
    ((values && values.paymentMethod !== "MOBILE_PAYMENT") ||
      (values && values.paymentMethod !== "CREDIT_CARD")) &&
    values.paymentMethod
  ) {
    return {
      paymentMethod: values.paymentMethod,
    };
  }

  return null;
};

export const deleteCustomIcon = () => {
  return (
    <ListItemIcon
      style={{
        textDecoration: "none",
        textTransform: "none",
        color: "#757575",
      }}
    >
      <Delete />
      <span className="deleteCustomIcon">Supprimer</span>
    </ListItemIcon>
  );
};

// Cash-ins and cash-outs edit value
export const cashInEditValue = (cashIn, initValues) =>
  cashIn && cashIn.length > 0
    ? cashIn.map((res) => {
        return Object.assign(
          {},
          {
            date:
              res.theoreticalPaymentDate !== null
                ? renderDateFormat(
                    res.theoreticalPaymentDate,
                    FORMAT_DATE_MOMENT
                  )
                : null,
            amount: res.theoreticalAmount,
          }
        );
      })
    : initValues?.deadlinesCashIn || [{ date: null, amount: 0 }];
export const cashOutEditValue = (cashOut, initValues) =>
  cashOut && cashOut.length > 0
    ? cashOut.map((res) => {
        return Object.assign(
          {},
          {
            date:
              res.theoreticalPaymentDate !== null
                ? renderDateFormat(
                    res.theoreticalPaymentDate,
                    FORMAT_DATE_MOMENT
                  )
                : null,
            amount: res.theoreticalAmount,
          }
        );
      })
    : initValues?.deadlinesCashOut || [{ date: null, amount: 0 }];

// BudgetaryLine edit value of cash-ins and cash-outs
export const budgetaryLineCashInEditValues = (cashIn, initValues) =>
  cashIn && cashIn.length > 0
    ? cashIn[0]?.budgetaryLine?.id
    : initValues.budgetaryLineCashIn || null;
export const budgetaryLineCashOutEditValues = (cashOut, initValues) =>
  cashOut && cashOut.length > 0
    ? cashOut[0]?.budgetaryLine?.id
    : initValues.budgetaryLineCashOut || null;

// TermType of cashIn and cashOut
export const termTypeEditValues = (initValues) =>
  initValues && initValues.id ? "VARIABLE" : initValues?.termType || "FIXED";
export const reimbursementTermType = (initValues) =>
  initValues && initValues.id
    ? "VARIABLE"
    : initValues?.reimbursementTermType || "FIXED";

export const InitialValueOfSendEmail = {
  senderFullName: "",
  recipientEmail: "",
  comment: "",
};

export const InitCashFlowValues = (cashFlowValue) => {
  return {
    effectiveAmount: cashFlowValue?.theoreticalAmount || 0,
    paymentMode: null,
    paymentLabel: null,
    effectivePaymentDate:
      cashFlowValue?.theoreticalPaymentDate !== null
        ? renderDateFormat(
            cashFlowValue?.theoreticalPaymentDate,
            FORMAT_DATE_MOMENT
          )
        : null,
    comments: "",
    paymentMethod: "CASH",
    cvc: "",
    expirationDate: null,
    cardNumber: "",
    mobileNumber: "",
  };
};

export const InitCashFlowValidation = Yup.object({
  effectivePaymentDate: Yup.string()
    .nullable()
    .required("Ce champ ne peut être vide."),
  paymentLabel: Yup.string().nullable().required("Ce champ ne peut être vide."),
});

export const SendEmailValidation = Yup.object({
  recipientEmail: Yup.string()
    .email("Email n'est pas valide")
    .required("Veuillez saisir un e-mail valide."),
  senderFullName: Yup.string().required("Ce champ ne peut être vide."),
});

export const InitCreditNoteValidation = Yup.object({
  creditDate: Yup.date()
    .test("creditDate", null, (value, context) => {
      if (
        (value && !renderDateFormat(value, FORMAT_DATE_MOMENT)) ||
        dayJs(renderDateFormat(value, FORMAT_DATE_MOMENT)).isBefore(
          renderDateFormat(context.parent.invoiceDate, FORMAT_DATE_MOMENT)
        )
      ) {
        return context.createError({
          message:
            "La date de la facture d'avoir ne doit pas être antérieure à celle de doit et doit être comprise dans l'année de l'exercice budgétaire",
          path: "creditDate",
        });
      }
    })
    .required("Ce champ ne peut être vide."),
});

export const makeGlobalReseachAccordingToModule = (
  props,
  module,
  moduleService,
  moduleItems,
  moduleScrollPage,
  searchTerm,
  setIsloading,
  setMessage
) => {
  setIsloading(true);
  const params = {
    searchType: SEARCH_TYPE,
  };

  params.monthSelected = props.monthSelected ? props.monthSelected : null;
  params.limit = props.limit ? props.limit : null;
  params.page = props.page ? props.page : null;

  setMessage("La recherche est en cours...");
  moduleService
    .getGlobalResearch(props.exerciceId, module, searchTerm, params)
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.length > 0 &&
        searchTerm &&
        searchTerm.length > 0
      ) {
        const currentValue = [...response.data];
        currentValue.map((line) => (line["isCollapsed"] = false));
        const responses =
          module && module === "quoteRequest" ? currentValue : response.data;

        moduleItems(responses);
        moduleScrollPage(2);
        setIsloading(false);
      } else {
        moduleItems([]);
        setMessage("Le résultat de la recherche est vide.");
      }
    })
    .catch(() => {
      toast.warn("Une erreur est survenue lors de la recherche");
      setIsloading(false);
    });
};

export const deadlinesNumberCalcultion = (
  periodicity,
  duration,
  durationUnit,
  date
) => {
  if ((periodicity && duration, durationUnit && date)) {
    let duree;
    switch (durationUnit) {
      case "DAILY":
        duree = duration;
        break;
      case "MONTHLY":
        duree = duration * 30;
        break;
      case "ANNUAL":
        duree = duration * 360;
        break;
      default:
        return null;
    }

    const startDate = dayJs(date);
    const endDate = startDate.add(duree, "days");
    let day = startDate;
    let deadLineNumber = 0;
    let valueToAdd = 1;

    periodicity?.valueConversion === "trimestrial"
      ? ((periodicity.valueConversion = "months"), (valueToAdd = 3))
      : periodicity?.valueConversion === "semestrial"
      ? ((periodicity.valueConversion = "months"), (valueToAdd = 6))
      : null;

    do {
      day = day.add(valueToAdd, periodicity?.valueConversion);
      deadLineNumber++;
    } while (day < endDate);

    return deadLineNumber || 0;
  }
};

export const translateLabelName = {
  CASH: "Espèce",
  CHEQUE: "Chèque",
  CREDIT_CARD: "Carte de crédit",
  DEBIT: "Prélèvement",
  MOBILE_PAYMENT: "Paiement mobile",
};

export const roundNumberAccordingToValueEntered = (value, number) =>
  value ? `${value.toFixed(number) + " % "}` : `${0 + " % "}`;

export const DisplayAmountOfProductsAccordingToTvaRate = (params) => {
  const produits = params && params.tvaData;
  const finalProducts = produits && produits.length > 0 ? produits : [];

  return (
    <TableBody>
      {finalProducts.map((product, index) => (
        <StyledTableRow key={index}>
          <StyledTableCellPreview>
            {product?.tvaRate !== null
              ? MyLocalString(product?.tvaRate)
              : "N/A"}
          </StyledTableCellPreview>

          <StyledTableCellPreview>
            {product.base !== null ? MyLocalString(product?.base) : 0}
          </StyledTableCellPreview>

          <StyledTableCellPreview>
            {product?.amount !== null ? MyLocalString(product?.amount) : 0}
          </StyledTableCellPreview>
        </StyledTableRow>
      ))}
    </TableBody>
  );
};

export const InformationCompanyHeaderInvoice = ({
  customerFolder,
  company,
}) => {
  const typographyStyled = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const getValueByPathName = (object, path, defaultValue) =>
    _.get(object, path, defaultValue);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "center",
        width: "55%",
        margin: "auto",
      }}
    >
      <Typography style={typographyStyled}>
        <span>{(customerFolder && customerFolder.social_reason) || ""}</span>
        <span>{company && company.company && "  -  "}</span>
        <span>{(company && company.company) || ""}</span>
        <span>{customerFolder && customerFolder.city && "  -  "}</span>

        {customerFolder && customerFolder.city && <BiWorld />}
        <span>{(customerFolder && customerFolder.city) || ""}</span>
      </Typography>
      <Typography style={typographyStyled}>
        {customerFolder.phone_number && <BsFillTelephoneFill />}
        <span> {(customerFolder && customerFolder.phone_number) || ""}</span>
        <span>{customerFolder && customerFolder.phone_number && "  -  "}</span>
        {customerFolder && customerFolder.email && <MdEmail />}
        <span>{(customerFolder && customerFolder.email) || ""}</span>
      </Typography>
      <Typography style={typographyStyled}>
        {getValueByPathName(customerFolder, "fiscal_folders[0].nrc", "") && (
          <span>
            NCC:{" "}
            {getValueByPathName(customerFolder, "fiscal_folders[0].ncc", "")}
          </span>
        )}
        {getValueByPathName(customerFolder, "fiscal_folders[0].nrc", "") &&
          "  -  "}

        {getValueByPathName(customerFolder, "fiscal_folders[0].nrc", "") && (
          <span>
            NRC:{" "}
            {getValueByPathName(customerFolder, "fiscal_folders[0].nrc", "")}
          </span>
        )}
        {getValueByPathName(
          customerFolder,
          "social_informations[0].social_capital",
          ""
        ) && "  -  "}

        {getValueByPathName(
          customerFolder,
          "social_informations[0].social_capital",
          ""
        ) && (
          <span>
            Capital:{" "}
            {getValueByPathName(
              customerFolder,
              "social_informations[0].social_capital",
              ""
            )}
            {"  Frcs  "}
          </span>
        )}
      </Typography>
    </Box>
  );
};

export const displayRegularByPaymentMode = (moduleName, data) => {
  if (DISPLAY_PAYMENT_MODE[data?.paymentMode?.paymentMethod] === "Espece") {
    return `${moduleName} 
    à régler par ${DISPLAY_PAYMENT_MODE[data?.paymentMode?.paymentMethod]}`;
  }

  if (DISPLAY_PAYMENT_MODE[data?.paymentMode?.paymentMethod] === "Chèque") {
    return `${moduleName} 
    à régler par ${DISPLAY_PAYMENT_MODE[data?.paymentMode?.paymentMethod]}`;
  }

  if (DISPLAY_PAYMENT_MODE[data?.paymentMode?.paymentMethod] === "Virement") {
    return `${moduleName}
    à régler par ${DISPLAY_PAYMENT_MODE[data?.paymentMode?.paymentMethod]}`;
  }
  if (
    DISPLAY_PAYMENT_MODE[data?.paymentMode?.paymentMethod] === "Mobile money"
  ) {
    return `${moduleName}
    à régler par ${
      DISPLAY_PAYMENT_MODE[data?.paymentMode?.paymentMethod]
    } sur le compte suivant: 
    ${data.paymentMode?.mobileNumber || "N/A"}`;
  }

  if (
    DISPLAY_PAYMENT_MODE[data?.paymentMode?.paymentMethod] === "Carte de credit"
  ) {
    return `${moduleName}
      à régler par ${
        DISPLAY_PAYMENT_MODE[data?.paymentMode?.paymentMethod]
      } sur le compte suivant:
      ${data.paymentMode?.cardNumber || "N/A"}`;
  }
};

export const TableModalHistoriqueEvent = (historyData, handleClosePopup) => {
  return (
    <div className="flex flex-col">
      <Grid item md={12} xs={12} align="center">
        <Table stickyHeader className="w-full">
          <TableHead>
            <TableRow>
              <StyledTableCellPreview align="center">
                Date
              </StyledTableCellPreview>
              <StyledTableCellPreview align="center">
                Évènement
              </StyledTableCellPreview>
              <StyledTableCellPreview align="center">
                Nom
              </StyledTableCellPreview>
              <StyledTableCellPreview align="center">
                Numéro
              </StyledTableCellPreview>
            </TableRow>
          </TableHead>
          <TableBody>
            {historyData &&
              historyData.map((event, index) => (
                <TableRow key={index}>
                  <StyledTableCell align="center">
                    {event && event.updatedAt && event.updatedAt !== null
                      ? renderDateFormat(event.updatedAt)
                      : "N/A"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {"Modification"}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {event?.clientName}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {event?.clientNumber}
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Grid>
      <DialogActions className="mt-24">
        <Grid
          item
          md={12}
          xs={12}
          className="flex items-center justify-center w-full h-full p-24"
        >
          <Button className="btn--green w-2/6" onClick={handleClosePopup}>
            Fermer
          </Button>
        </Grid>
      </DialogActions>
    </div>
  );
};
